'use client';

import React, { createContext, useContext } from 'react';

type FeatureFlagContextType = {
  enabledFeatures: string[];
};

const FeatureFlagContext = createContext<FeatureFlagContextType>({
  enabledFeatures: [],
});

export const useFeatureFlagContext = () => {
  return useContext(FeatureFlagContext);
};

export const FeatureFlagProvider: React.FC<{
  featureFlagsData: FeatureFlagContextType;
  children: React.ReactNode;
}> = ({ featureFlagsData, children }) => {
  return (
    <FeatureFlagContext.Provider value={featureFlagsData}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
