import { useRouter } from 'next/navigation';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';

export const useSessionCheck = () => {
  const router = useRouter();
  const isCheckingRef = useRef(false);

  useEffect(() => {
    const checkSession = async () => {
      if (isCheckingRef.current) return;
      isCheckingRef.current = true;

      try {
        const response = await fetch('/api/auth/session');
        const data = await response.json();
        if (!data.user) {
          enqueueSnackbar(
            'You have been logged out. Please sign in again to continue.',
            { variant: 'info' },
          );
          router.push('/');
        }
      } finally {
        isCheckingRef.current = false;
      }
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) checkSession();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', checkSession);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', checkSession);
    };
  }, [router]);

  return null;
};
