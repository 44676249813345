'use client';
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Image from 'next/image';
import { signOut } from 'next-auth/react';
import React, { useEffect } from 'react';
import { useLocalStorage, useMediaQuery } from 'usehooks-ts';

import covAvatarWhite from '@/public/images/covy/avatar-white.png';
import { Logo } from '@/src/app/_components/Icons';
import { SidebarNav } from '@/src/app/_components/layout/sidebar/SidebarNav';
import { Link } from '@/src/app/_components/ui/Link';
import { BrandLogo } from '@/src/common/components/BrandLogo';
import { Brand, User } from '@/types/common';

const onSignOut = () => {
  signOut({ callbackUrl: '/api/auth/logout' });
};

export const Sidebar = ({ user, brand }: { user: User; brand?: Brand }) => {
  const [isExpanded, setExpanded] = useLocalStorage(
    'isSidebarExpanded',
    false,
    {
      initializeWithValue: false,
    },
  );

  const isWideScreen = useMediaQuery('(min-width: 1680px)');

  useEffect(() => {
    setExpanded(isWideScreen);
  }, [isWideScreen, setExpanded]);

  if (!brand) return;

  return (
    <div
      className={clsx(
        'group relative flex h-screen shrink-0 flex-col overflow-hidden border-r border-secondary bg-primary transition-all ease-linear',
        { 'w-[80px]': !isExpanded, 'w-[362px]': isExpanded },
      )}
      onClick={() => (isExpanded ? {} : setExpanded(true))}
    >
      <button
        onClick={() => setExpanded((prev) => !prev)}
        className={clsx(
          'fixed top-9 rounded-full px-1 py-1.5 text-tertiary hover:text-primary',
          {
            'left-[54px] hidden group-hover:block': !isExpanded,
            'left-[320px]': isExpanded,
          },
        )}
      >
        <ChevronDoubleRightIcon
          strokeWidth={2}
          className={clsx('h-4 w-4 rounded-full bg-primary', {
            'rotate-180': isExpanded,
          })}
        />
      </button>
      <Link
        href="/"
        className="w-max py-8"
        onClick={(e) => e.stopPropagation()}
      >
        {isExpanded ? (
          <div className="ml-8 mt-[1px] self-start transition-all">
            <Logo />
          </div>
        ) : (
          <Image
            src={covAvatarWhite}
            alt="covariance.ai"
            height={32}
            className="ml-[25px] self-start transition-all"
          />
        )}
      </Link>
      <div
        className={clsx('mb-28 h-full px-1', {
          'overflow-y-auto': isExpanded,
        })}
      >
        <SidebarNav
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          isAdmin={user.isAdmin}
        />
      </div>

      <div
        className={clsx(
          'absolute bottom-0 flex w-full items-center justify-between gap-lg justify-self-end bg-primary pb-9 pl-4 pt-6 text-primary',
          { 'pr-4': isExpanded },
        )}
      >
        {user.brandId && user.isAdmin ? (
          <Link
            href={`/admin/brands/${user.brandId}`}
            onClick={(e) => e.stopPropagation()}
            className="shrink-0"
          >
            <BrandLogo
              brand={{ color: user.color, imageName: brand?.imageName ?? null }}
              size={12}
            />
          </Link>
        ) : (
          <BrandLogo
            brand={{ color: user.color, imageName: brand?.imageName ?? null }}
            size={12}
            className="shrink-0"
          />
        )}
        {isExpanded && (
          <>
            <a
              href="#"
              className="hover:bg-active-1 flex grow items-center gap-x-4 truncate leading-6"
            >
              <div className="items-flex-start flex flex-col">
                <span
                  aria-hidden="true"
                  className="font-semibold text-secondary"
                >
                  {user.name}
                </span>
                <span aria-hidden="true" className="text-sm text-tertiary">
                  {user.email}
                </span>
              </div>
            </a>
            <button onClick={onSignOut}>
              <ArrowRightStartOnRectangleIcon className="h-5 w-5 text-tertiary hover:text-primary" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
