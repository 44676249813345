'use client';

import BaseLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import { MouseEventHandler } from 'react';

export const Link = ({
  children,
  href,
  preserveQuery = false,
  scroll,
  onClick,
  className,
}: {
  active?: boolean;
  children: React.ReactNode;
  href: string;
  preserveQuery?: boolean;
  scroll?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}) => {
  const query = useSearchParams();

  return (
    <BaseLink
      href={preserveQuery && query ? href + '?' + query.toString() : href}
      scroll={scroll}
      className={className}
      onClick={onClick}
    >
      {children}
    </BaseLink>
  );
};
